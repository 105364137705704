<template>
  <div class="client-card">
    <loading-default
      :loadingVisible="loadingVisible"
      :headline="loadingHeadline"
      :excerpt="loadingExcerpt"
      :showRestoreCart="true"
      :abort="abort"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'client-card',
  components: {
    LoadingDefault: () => import('../components/shared/loading/Default.vue'),
  },
  data() {
    return {
      clientCardNr: '',
      /**
       * Loading
       */
      loadingVisible: true,
      loadingHeadline: this.$t('terminal.loading.membership.headline'),
      loadingExcerpt: this.$t('terminal.loading.membership.description'),
    };
  },
  async created() {
    window.addEventListener('keydown', this.keyDownHandler);
  },
  methods: {
    ...mapActions([
      'clearCart',
    ]),

    keyDownHandler(e) {
      // 4001724819004 // 4001686315354 // 2704493005006
      const keyCode = e.keyCode || e.which;
      const code = e.code || '';
      let char = e.key;

      if (keyCode === 89 && code === 'KeyY' && char === 'Y') char = 'Z';
      else if (keyCode === 89 && code === 'KeyY' && char === 'y') char = 'z';
      else if (keyCode === 90 && code === 'KeyZ' && char === 'Z') char = 'Y';
      else if (keyCode === 90 && code === 'KeyZ' && char === 'z') char = 'y';

      if (char.length > 1) char = '';

      // scan articles
      this.clientCardNr += char;

      if (e.keyCode === 13) {
        const card = this.clientCardNr;
        this.clientCardNr = '';
        this.addClientCard(card);
      }
    },

    addClientCard(card) {
      if (card === '123456789') {
        this.success('Die Karte wurde erfolgreich gescannt.'); // translate

        // save the card number
        localStorage.setItem('clientCardNr', card);

        // redirect to the cart
        setTimeout(() => {
          this.$router.push('/cart');
        }, 350);
      } else {
        this.error('Das Scannen der Karte ist fehlgeschlagen, bitte versuchen Sie es erneut.'); // translate
      }
    },

    async abort() {
      // reset store cart
      await this.clearCart();

      const formData = new FormData();
      /**
       * Locale
       */
      formData.append('locale', localStorage.getItem('boxLang'));
      // Logout data
      await this.$http.post(`${localStorage.getItem('anybox_terminal_api_url')}/logout`, formData, {
        headers: {
          'X-Api-Key': `${localStorage.getItem('anybox_terminal_api_key')}`,
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
      })
        .then((response) => {
          if (response.data) {
            // mutating to store for client rendering &
            // saving token in cookie for server rendering
            this.$store.commit('SET_AUTH', null);
            localStorage.removeItem('auth');

            // mutating to store for client rendering &
            // saving user data in cookie for server rendering
            this.$store.commit('SET_USER', null);
            localStorage.removeItem('user');

            // clear other important data
            localStorage.removeItem('qrcodeActive');

            // clear client card nr.
            localStorage.removeItem('clientCardNr');
          }
        });

      // redirect to home + reload app
      window.location.assign('/');
    },

    success(message) {
      this.$notify({
        message,
        type: 'success',
        position: 'bottom-right',
        showClose: false,
        duration: 1500,
      });
    },

    error(message, duration, showClose) {
      if (message) {
        this.$notify({
          message,
          type: 'error',
          position: 'bottom-right',
          showClose: showClose || false,
          duration: duration || 1500,
        });
      } else {
        this.$notify({
          message: this.$t('cart.product.failed'),
          type: 'error',
          position: 'bottom-right',
          showClose: showClose || false,
          duration: duration || 1500,
        });
      }
    },
  },
};
</script>
